import { z } from "zod";

export const CARBON_BUILDER_APPLICATION_METHODS = [
  { label: "FurrowMate", value: "furrowmate" },
  { label: "Seed", value: "seed" },
];

export const PREVIOUS_CROP_RESIDUE_OPTIONS = [
  { label: "Grazed", value: "grazed" },
  { label: "Burnt", value: "burnt" },
  {
    label: "Windrow Burnt",
    value: "windrowBurnt",
  },
  { label: "Cut", value: "cut" },
  { label: "Baled", value: "baled" },
  { label: "Mulched", value: "mulched" },
  {
    label: "Left Standing",
    value: "leftStanding",
  },
  {
    label: "Tilled",
    value: "tilled",
  },
];

export const CROP_COMPLETION_STATUSES = [
  "completed",
  "inProgress",
  "notStarted",
] as const;
export type CROP_COMPLETION_STATUS = (typeof CROP_COMPLETION_STATUSES)[number];

export const CROP_STATUS_COLOUR: Record<
  CROP_COMPLETION_STATUS | "notSelectable",
  string
> = {
  completed: "#03880e",
  inProgress: "#439995",
  notStarted: "#C0C0C0",
  notSelectable: "#808080",
} as const;

export const CROPS = [
  "wheat",
  "barley",
  "canola",
  "maize",
  "oats",
  "sorghum",
  "fallow",
  "triticale",
  "pulses",
  "cotton",
  "otherCereals",
  "oilseeds",
  "annualGrass",
  "grassCloverMixture",
  "lucerne",
  "otherLegume",
  "perennialPasture",
  "coverCrop",
  "forageCrop",
] as const;

export const cropSchema = z.enum(CROPS);
export type CropType = z.infer<typeof cropSchema>;

const CROP_PALETTE = [
  "#FA8803",
  "#D27202",
  "#A95C02",
  "#814601",
  "#CA6D02",
  "#C68C06",
  "#B36B05",
  "#794201",
  "#D69706",
  "#8F6504",
  "#CE9206",
  "#FD5C00",
  "#D44D00",
  "#AB3E00",
  "#832F00",
  "#CC4A00",
  "#A33B00",
  "#A67605",
  "#9B3800",
  "#B68105",
  "#875F04",
];

export const CROP_COLOURS: Record<CropType, string> = {
  wheat: CROP_PALETTE[0],
  barley: CROP_PALETTE[1],
  canola: CROP_PALETTE[2],
  maize: CROP_PALETTE[3],
  oats: CROP_PALETTE[4],
  sorghum: CROP_PALETTE[5],
  fallow: CROP_PALETTE[6],
  triticale: CROP_PALETTE[7],
  pulses: CROP_PALETTE[8],
  cotton: CROP_PALETTE[9],
  otherCereals: CROP_PALETTE[10],
  oilseeds: CROP_PALETTE[11],
  annualGrass: CROP_PALETTE[12],
  grassCloverMixture: CROP_PALETTE[13],
  lucerne: CROP_PALETTE[14],
  otherLegume: CROP_PALETTE[15],
  perennialPasture: CROP_PALETTE[16],
  coverCrop: CROP_PALETTE[17],
  forageCrop: CROP_PALETTE[18],
};

type CropCategory = "carbonbuilder" | "pasture" | "covercrop" | "crop";

export const CROP_OPTIONS: {
  label: string;
  value: CropType;
  category: CropCategory;
}[] = [
  {
    label: "Wheat",
    value: "wheat",
    category: "carbonbuilder",
  },
  {
    label: "Barley",
    value: "barley",
    category: "carbonbuilder",
  },
  {
    label: "Canola",
    value: "canola",
    category: "carbonbuilder",
  },
  {
    label: "Maize",
    value: "maize",
    category: "crop",
  },
  {
    label: "Oats",
    value: "oats",
    category: "crop",
  },
  {
    label: "Sorghum",
    value: "sorghum",
    category: "crop",
  },
  {
    label: "Fallow",
    value: "fallow",
    category: "crop",
  },
  {
    label: "Triticale",
    value: "triticale",
    category: "crop",
  },
  {
    label: "Pulses",
    value: "pulses",
    category: "crop",
  },
  {
    label: "Cotton",
    value: "cotton",
    category: "crop",
  },
  {
    label: "Other Cereals",
    value: "otherCereals",
    category: "crop",
  },
  {
    label: "Oilseeds",
    value: "oilseeds",
    category: "crop",
  },
  {
    label: "Annual Grass",
    value: "annualGrass",
    category: "pasture",
  },
  {
    label: "Grass Clover Mixture",
    value: "grassCloverMixture",
    category: "pasture",
  },
  {
    label: "Lucerne",
    value: "lucerne",
    category: "pasture",
  },
  {
    label: "Other Legume",
    value: "otherLegume",
    category: "pasture",
  },
  {
    label: "Perennial Pasture",
    value: "perennialPasture",
    category: "pasture",
  },
  {
    label: "Cover Crop",
    value: "coverCrop",
    category: "covercrop",
  },
  {
    label: "Forage Crop",
    value: "forageCrop",
    category: "covercrop",
  },
];
