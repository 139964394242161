import type { SelectEmissionsPaddockCrop } from "~/db/schema";
import type { PaddockWithoutGeometry } from "~/modules/paddock/schema";
import type {
  EmissionsReport,
  EvidenceType,
  FullEmissionsReport,
  MissingEmissionsReport,
  PaddockCropRecord,
} from "~/modules/reporting/emissions/schema";
import { CROP_OPTIONS } from "./constants";

export type EmissionsReportWarnings = {
  header: string;
  messages: string[];
  href: string;
}[];

const TOTAL_STEPS = 4;

export function getEmissionsReportStatus(
  report: FullEmissionsReport,
  paddockIds: string[],
) {
  const projectWideActivitiesCompleted =
    report.areaIrrigated !== null &&
    report.livestockInProjectArea !== null &&
    report.limeApplied !== null &&
    report.dolomiteApplied !== null &&
    report.soilLandscapeModification !== null;

  const cropsCompleted =
    report.emissionsPaddockCrops &&
    paddockIds.every((id) =>
      report.emissionsPaddockCrops.some((pc) => pc.paddockId === id),
    ) &&
    paddockCropsInfoComplete(report.emissionsPaddockCrops);

  const restrictedActivitiesCompleted =
    report.soilRedistribution !== null &&
    report.soilDisturbed !== null &&
    report.woodyVegetationRemoved !== null &&
    report.pyrolysisedMaterialApplied !== null &&
    report.thinningOfWoodyBiomassOccurred !== null &&
    report.biocharApplied !== null &&
    report.coalApplied !== null &&
    report.pasturedLandDestocked !== null;

  const missingEvidence = getIncompleteEvidenceRecords(report);
  const evidenceUploadCompleted =
    report.emissionsPaddockCrops.length > 0 && missingEvidence.length === 0;

  return {
    projectWideActivitiesCompleted,
    cropsCompleted,
    restrictedActivitiesCompleted,
    evidenceUploadCompleted,
  };
}

export function getEmissionsReportProgress(
  report: EmissionsReport | MissingEmissionsReport,
) {
  if (!report.status) {
    return {
      steps: `0/${TOTAL_STEPS}`,
      percentage: 0,
    };
  }

  const completedSteps = Object.values(report.status).filter(Boolean).length;

  return {
    steps: `${completedSteps}/${TOTAL_STEPS}`,
    percentage: Math.round((completedSteps / TOTAL_STEPS) * 100),
  };
}

export function paddockCropsInfoComplete(paddockCrops: PaddockCropRecord[]) {
  return paddockCrops.every(
    (crop) =>
      crop.crop &&
      crop.previousCropResidue &&
      crop.sowingDate &&
      crop.harvestDate &&
      crop.completed,
  );
}

function validatePaddockCrops(
  paddocks: PaddockWithoutGeometry[],
  paddockCrops: PaddockCropRecord[],
) {
  const warningMsgs = [];

  const paddocksMissingCrops = paddocks.filter((paddock) => {
    return !paddockCrops.some((crop) => crop.paddockId === paddock.id);
  });
  if (paddocksMissingCrops.length === paddocks.length) {
    warningMsgs.push("No paddocks have crop information filled out");
  } else if (paddocksMissingCrops.length > 0) {
    warningMsgs.push("Some paddocks are missing crops");
  }

  if (!paddockCropsInfoComplete(paddockCrops)) {
    warningMsgs.push(
      "Some paddock's crops are missing a crop name, sowing date, harvest date, previous crop residue, or completion status",
    );
  }

  return warningMsgs;
}

function validateRestrictedActivities(report: FullEmissionsReport) {
  const warningMsgs = [];

  if (
    [
      report.soilRedistribution,
      report.soilDisturbed,
      report.woodyVegetationRemoved,
      report.pyrolysisedMaterialApplied,
      report.thinningOfWoodyBiomassOccurred,
      report.biocharApplied,
      report.coalApplied,
      report.pasturedLandDestocked,
    ].includes(null)
  ) {
    warningMsgs.push("Restricted activities have not been filled out");
  }

  return warningMsgs;
}

function validateEvidenceRecords(record: FullEmissionsReport) {
  const evidenceRecords = record.evidenceRecords;

  if (evidenceRecords.length === 0) {
    return ["No supporting evidence has been uploaded yet"];
  }

  const warningMsgs = [];

  const incompleteEvidenceRecords = getIncompleteEvidenceRecords(record);
  const evidenceMessages: { [key in EvidenceType]?: string } = {
    syntheticFertiliserInvoice: "Missing invoice for synthetic fertilisers",
    nonSyntheticFertiliserInvoice:
      "Missing invoice for non-synthetic fertilisers",
    nonSyntheticFertiliserLabel: "Missing label for non-synthetic fertilisers",
    rotationPlan: "Missing sowing or rotation plan",
    harvestYield: "Missing grain dockets or seed sales",
    livestock: "Missing stock program or livestock numbers",
    dolomite: "Missing invoice for dolomite",
    lime: "Missing invoice for lime",
    soilLandscapeModification:
      "Missing invoice for diesel used or contractor invoice for soil landscape modification",
    irrigation: "Missing invoice for diesel or electricity used for irrigation",
  };

  for (const evidenceRecord of incompleteEvidenceRecords) {
    const message =
      evidenceMessages[evidenceRecord] ||
      `Missing evidence for ${evidenceRecord}`;
    warningMsgs.push(message);
  }

  return warningMsgs;
}

function getIncompleteEvidenceRecords(record: FullEmissionsReport) {
  const evidenceRecords = record.evidenceRecords;
  const required = new Set(
    getRequiredEvidenceRecords(record).map((r) => r.type),
  );
  const have = new Set(evidenceRecords.map((record) => record.evidenceType));

  return Array.from(required).filter((type) => !have.has(type));
}

export function validateEmissionsReport(
  report: FullEmissionsReport,
  paddocks: PaddockWithoutGeometry[],
) {
  const warnings: EmissionsReportWarnings = [];

  const paddockCropWarnings = validatePaddockCrops(
    paddocks,
    report.emissionsPaddockCrops,
  );
  if (paddockCropWarnings.length > 0) {
    warnings.push({
      header: "Crops",
      messages: paddockCropWarnings,
      href: `/reporting/emissions/${report.id}/crops`,
    });
  }

  const restrictedActivitiesWarnings = validateRestrictedActivities(report);
  if (restrictedActivitiesWarnings.length > 0) {
    warnings.push({
      header: "Restricted activities",
      messages: restrictedActivitiesWarnings,
      href: `/reporting/emissions/${report.id}/restricted-activities`,
    });
  }

  const evidenceWarnings = validateEvidenceRecords(report);
  if (evidenceWarnings.length > 0) {
    warnings.push({
      header: "Evidence upload",
      messages: evidenceWarnings,
      href: `/reporting/emissions/${report.id}/evidence-upload`,
    });
  }

  return warnings;
}

/**
 * Find the paddock crops for the selected paddocks.
 * We determine the initial crop records to render on this form by:
 * 1. Filtering the paddock crops to only include those that are associated with the selected paddocks
 * 2. Check that, per crop, the records are the same for all selected paddocks
 * 3. If the records are the same, we return the records as the initial values
 * 4. If the records are not the same, we return an empty object. If the user saves the record,
 * all existing records will be removed and replaced with the new records they enter.
 *
 * @param selectedPaddocks The paddocks that the user has selected to compare the crops of
 * @param paddockCrops All of the paddockCrops associated with the report
 * @returns a list of distinct crops and their values. There should be only one record per crop,
 * and in this function we have checked that all the selected paddocks also have the same values.
 */
export function determineInitialCropRecords(
  selectedPaddocks: { id: string }[],
  paddockCrops: SelectEmissionsPaddockCrop[],
) {
  if (selectedPaddocks.length === 0) {
    return { paddockCropRecords: [], hadRecordsButNotSame: false };
  }

  const selectedPaddockCrops = paddockCrops.filter((paddockCrop) => {
    return selectedPaddocks.some(
      (paddock) => paddock.id === paddockCrop.paddockId,
    );
  });

  const initialValues: Partial<SelectEmissionsPaddockCrop>[] = [];
  let hadRecordsButNotSame = false;

  // Group the paddock crops by crop
  const crops = new Map<string, SelectEmissionsPaddockCrop[]>();
  for (const paddockCrop of selectedPaddockCrops) {
    if (!crops.has(paddockCrop.crop)) {
      crops.set(paddockCrop.crop, []);
    }
    crops.get(paddockCrop.crop)?.push(paddockCrop);
  }

  // Compare the similar crops.
  for (const [_, cropRecords] of crops) {
    if (cropRecords.length === selectedPaddocks.length) {
      // Check if all selected paddocks have this crop
      const firstRecord = cropRecords[0];
      const allSame = cropRecords.every((record) =>
        isPaddockCropRecordEqual(firstRecord, record),
      );

      if (allSame) {
        initialValues.push(firstRecord);
      } else {
        hadRecordsButNotSame = true;
        break; // Exit loop after finding the first mismatch to avoid adding multiple empty objects.
      }
    } else {
      hadRecordsButNotSame = true;
      break; // Exit loop after finding the first mismatch to avoid adding multiple empty objects.
    }
  }

  return { paddockCropRecords: initialValues, hadRecordsButNotSame };
}

/**
 * Compare two paddock crop records to determine if they are equal.
 * We exclude timestamps and ids
 */
function isPaddockCropRecordEqual(
  a: SelectEmissionsPaddockCrop,
  b: SelectEmissionsPaddockCrop,
) {
  return (
    a.ureaRateKgPerHa === b.ureaRateKgPerHa &&
    a.ammoniumSulfateRateKgPerHa === b.ammoniumSulfateRateKgPerHa &&
    a.diAmmoniumPhosphateRateKgPerHa === b.diAmmoniumPhosphateRateKgPerHa &&
    a.monoAmmoniumPhosphateRateKgPerHa === b.monoAmmoniumPhosphateRateKgPerHa &&
    a.sowingDate === b.sowingDate &&
    a.harvestDate === b.harvestDate &&
    a.carbonBuilderCoverage === b.carbonBuilderCoverage &&
    a.carbonBuilderApplicationMethod === b.carbonBuilderApplicationMethod &&
    a.harvestYieldTPerHa === b.harvestYieldTPerHa &&
    a.cropRemovedTPerHa === b.cropRemovedTPerHa &&
    JSON.stringify(a.otherSyntheticFertilizers) ===
      JSON.stringify(b.otherSyntheticFertilizers) &&
    JSON.stringify(a.otherNonSyntheticFertilizers) ===
      JSON.stringify(b.otherNonSyntheticFertilizers)
  );
}

export function getRequiredEvidenceRecords(report: FullEmissionsReport) {
  const requiredEvidence: { type: EvidenceType; message: string }[] = [];

  // Check each fertilizer
  const syntheticFertilisers = new Set<string>();
  const nonSyntheticFertilisers = new Set<string>();
  const cropYields = new Map<string, number>();

  for (const crop of report.emissionsPaddockCrops) {
    if (crop.ureaRateKgPerHa != null && crop.ureaRateKgPerHa > 0) {
      syntheticFertilisers.add("Urea");
    }
    if (
      crop.ammoniumSulfateRateKgPerHa != null &&
      crop.ammoniumSulfateRateKgPerHa > 0
    ) {
      syntheticFertilisers.add("Sulfate of Ammonia (SOA)");
    }
    if (
      crop.diAmmoniumPhosphateRateKgPerHa != null &&
      crop.diAmmoniumPhosphateRateKgPerHa > 0
    ) {
      syntheticFertilisers.add("Di-Ammonium Phosphate");
    }
    if (
      crop.monoAmmoniumPhosphateRateKgPerHa != null &&
      crop.monoAmmoniumPhosphateRateKgPerHa > 0
    ) {
      syntheticFertilisers.add("Mono-Ammonium Phosphate");
    }
    if (
      crop.singleSuperRateKgPerHa != null &&
      crop.singleSuperRateKgPerHa > 0
    ) {
      syntheticFertilisers.add("Single Super");
    }

    for (const otherFertilizer of crop.otherSyntheticFertilizers || []) {
      syntheticFertilisers.add(otherFertilizer.name);
    }

    for (const otherFertilizer of crop.otherNonSyntheticFertilizers || []) {
      nonSyntheticFertilisers.add(otherFertilizer.name);
    }

    if (crop.harvestYieldTPerHa != null) {
      cropYields.set(
        crop.crop,
        (cropYields.get(crop.crop) || 0) + crop.harvestYieldTPerHa,
      );
    }
  }

  if (syntheticFertilisers.size > 0) {
    requiredEvidence.push({
      type: "syntheticFertiliserInvoice",
      message: `Please provide a copy of the invoice(s) for fertilizers: ${Array.from(
        syntheticFertilisers,
      ).join(", ")}`,
    });
  }

  if (nonSyntheticFertilisers.size > 0) {
    requiredEvidence.push({
      type: "nonSyntheticFertiliserInvoice",
      message: `Please provide a copy of the invoice(s) for fertilizers: ${Array.from(
        nonSyntheticFertilisers,
      ).join(", ")}`,
    });

    requiredEvidence.push({
      type: "nonSyntheticFertiliserLabel",
      message: `Please provide a copy of the product label(s) for fertilizers: ${Array.from(
        nonSyntheticFertilisers,
      ).join(", ")}`,
    });
  }

  if (report.emissionsPaddockCrops.length > 0) {
    requiredEvidence.push({
      type: "rotationPlan",
      message:
        "Please provide a copy of your sowing or rotation plan that includes all paddocks in your project area",
    });
  }

  const harvestCrops = [];
  for (const [crop, cropYield] of cropYields) {
    const cropCategory = CROP_OPTIONS.find(
      (option) => option.value === crop,
    )?.category;
    if (
      cropYield > 0 &&
      ["carbonbuilder", "crop"].includes(cropCategory || "unknown")
    ) {
      harvestCrops.push(crop);
    }
  }
  if (harvestCrops.length > 0) {
    requiredEvidence.push({
      type: "harvestYield",
      message: `Please provide a copy of grain dockets or seed sales from the harvest for crop(s): ${harvestCrops.join(", ")}`,
    });
  }

  if (report.livestockInProjectArea) {
    requiredEvidence.push({
      type: "livestock",
      message:
        "Please provide an attachment of your stock program or livestock numbers",
    });
  }

  if (report.dolomiteApplied) {
    requiredEvidence.push({
      type: "dolomite",
      message: "Please provide a copy of the invoice for the dolomite",
    });
  }

  if (report.limeApplied) {
    requiredEvidence.push({
      type: "lime",
      message: "Please provide a copy of the invoice for the lime",
    });
  }

  if (report.soilLandscapeModification) {
    requiredEvidence.push({
      type: "soilLandscapeModification",
      message:
        "For the soil modification works, please provide a copy of the invoice for the diesel used, or if a contractor was used please provide a copy of the contractor's invoice.",
    });
  }

  if (report.areaIrrigated) {
    requiredEvidence.push({
      type: "irrigation",
      message:
        "If diesel was used to irrigate the project area, please provide a copy of the invoice for diesel. If electricity was used to irrigate the project area please provide a copy of the electricity bill.",
    });
  }

  return requiredEvidence;
}

export const testHandles = {
  validatePaddockCrops,
  validateRestrictedActivities,
  validateEvidenceRecords,
  isPaddockCropRecordEqual,
  determineInitialCropRecords,
  getRequiredEvidenceRecords,
};
